"use client";

import { cn } from "@/lib/utils";
import Link from "next/link";

export default function Home() {
  // black page  centered text with the title "Welcome to Next.js!"
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center min-h-screen py-2"
      )}>
      <h1 className={cn("text-6xl font-bold")}>
        Welcome to Merch-Insights! V2
      </h1>
    </div>
  );
}
